@import './content/text';
@import './content/files';
@import './content/quote';
@import './content/gallery';
@import './content/seo';


.content-block{
    margin: 45px auto;
    @include _992{
        margin: 20px auto;
    }
}
