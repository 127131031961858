.search-form{
    position: relative;
    background-color: #FFFFFF;
    border: 1.5px solid #C7C8CF;
    border-radius: 8px;
    padding: 15px 25px;
    @include _1200{
        padding: 12px 15px;
    }
    &--header{
        width: 400px;
    }
    &__field{
        border: none;
        font-size: 21px;
        line-height: 26px;
        color: $text-clr;
        width: 100%;
        height: 100%;
        &::placeholder{
            color: #9A9BA8;
        }
        @include _1200{
            font-size: 15px;
            line-height: 18px;
        }
    }
    &__button{
        position: absolute;
        background-color: transparent;
        border: none;
        right: 20px;
        cursor: pointer;
        @include _1200{
            right: 15px;
        }
        svg{
            width: 28px;
            height: 28px;
            fill: #9A9BA8;
            @include _1200{
                width: 22px;
                height: 22px;
            }
        }
    }
}