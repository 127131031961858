@mixin _1920 {
	@media (max-width: 1919px) {
		@content;
	}
}

@mixin _1650 {
	@media (max-width: 1649px) {
		@content;
	}
}

@mixin _1200 {
	@media (max-width: 1199px) {
		@content;
	}
}
@mixin _992 {
	@media (max-width: 991px) {
		@content;
	}
}

@mixin _768 {
	@media (max-width: 767px) {
		@content;
	}
}

@mixin _576 {
	@media (max-width: 575px) {
		@content;
	}
}

@mixin _420 {
	@media (max-width: 419px) {
		@content;
	}
}