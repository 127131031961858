
.title-underline{
    border-bottom: 3px solid $main-clr-alt;
    padding: 0 0 12px;
}

.content-text{
    h1{
        font-weight: 900;
        font-size: 48px;
        line-height: 54px;
        color: $text-clr;
        margin: 0 0 25px;
        @include _1200{
            font-size: 24px;
            line-height: 28px;
            margin: 0 0 15px;
        }
    }
    h2{
        font-weight: 700;
        font-size: 42px;
        line-height: 46px;
        color: $text-clr;
        margin: 25px 0 16px;
        @include _1200{
            font-size: 22px;
            line-height: 26px;
            margin: 15px 0;
        }
        &:first-child{
            margin: 0 0 25px;
        }
    }
    h3, h4, h5, h6{
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: $text-clr;
        margin: 45px 0 15px;
        letter-spacing: .5px;
        @include _1200{
            font-size: 18px;
            line-height: 24px;
            margin: 15px 0;
        }
    }
    p, a, li{
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $text-clr;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
        }
    }
    p{
        white-space: break-spaces;
        margin: 0 0 25px;
        @include _1200{
            margin: 0 0 15px;
        }
    }
    a{
        font-weight: 900;
        color: $main-clr-alt;
        transition: color $tr-time, border-color $tr-time;
        border-bottom: 1px solid $main-clr-alt;
        @include _1200{
            font-weight: 700;
        }
        &:hover{
            color: $main-clr;
            border-bottom: 1px solid transparent;
        }
        &:visited{
            color: #9125C3;
            border-bottom: 1px solid #9125C3;
        }
    }
    img{
        max-width: 100%;
        // width: 100%;
        margin: 35px 0;
        @include _1200{
            margin: 20px 0;
        }
        &.emoji{
            max-width: 15px;
            max-height: 15px;
            display: inline;
            margin: 0 5px;
        }
    }

    ol, ul{
        padding-left: 18px;
        margin: 8px 0;
        li{
            padding-left: 10px;
            &:not(:last-child){
                margin: 0 0 10px;
            }

        }
    }

    ol{
        li{
            list-style-type: decimal;
        }
    }
    ul{
        li{
            list-style-type: none;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                background-color: $main-clr-alt;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: -18px;
                @include _1200{
                    width: 5px;
                    height: 5px;
                    left: -10px;
                }
            }
        }
    }
    .table-wrap{
        max-width: 100%;
        margin: 45px 0;
        @include _1200{
            overflow: scroll;
            margin: 20px 0 0;
        }
    }
    table{
        border: none;
        width: 100%;
        border-collapse: collapse;
        > caption{
            font-weight: 700;
            font-size: 42px;
            line-height: 46px;
            color: $text-clr;
            text-align: left;
            padding: 0px 0 24px;
            letter-spacing: 0.6px;
            @include _1200{
                font-size: 22px;
                line-height: 26px;
                padding: 0 0 15px;
            }
        }

        thead{
            border-bottom: 5px solid $main-clr-alt;
            @include _1200{
                border-bottom: 2px solid $main-clr-alt;
            }
            th{
                font-weight: 700;
                font-size: 24px;
                line-height: 28px;
                color: $text-clr;
                @include _1200{
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        tbody{
            border: none;
        }

        th, td{
            padding: 20px 24px;
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
            color: $text-clr;
            text-align: left;
            border: 1px solid $main-clr-alt;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
                padding: 15px;
            }
        }

        th{
            font-weight: 700;
        }
    }
}