.catalog-card{
    $this: &;
    display: grid;
    grid-template-columns: 110px 1fr;
    @include _1200{
        grid-template-columns: 80px 1fr;
    }
    &--no-photo{
        display: block;
        #{$this}__main{
            a{
                font-size: 21px;
                line-height: 26px;
            }
        }
        #{$this}__sub{
            a{
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
    &__image{
        width: 90px;
        height: 90px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @include _1200{
            width: 60px;
            height: 60px;
        }
    }
    &__main{
        &:not(:last-child){
            margin: 0 0 15px;
            @include _1200{
                margin: 0 0 10px;
            }
        }
        a{
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            color: #121212;
            @include _1200{
                font-size: 18px;
                line-height: 22px;
            }
            &:hover{
                color: $main-clr;
            }
        }
    }
    &__sub{
        a{
            display: block;
            font-size: 21px;
            line-height: 26px;
            color: #121212;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
            &:not(:last-child){
                margin: 0 0 12px;
            }
            &:hover{
                color: $main-clr;
            }
        }
    }
}