@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-BlackItalic.eot');
	src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
		url('../fonts/SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-BlackItalic.woff') format('woff'),
		url('../fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-BoldItalic.eot');
	src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
		url('../fonts/SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-BoldItalic.woff') format('woff'),
		url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Regular.eot');
	src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
		url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-Regular.woff') format('woff'),
		url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-SemiBoldItalic.eot');
	src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
		url('../fonts/SourceSansPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff'),
		url('../fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-LightItalic.eot');
	src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
		url('../fonts/SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-LightItalic.woff') format('woff'),
		url('../fonts/SourceSansPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Italic.eot');
	src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
		url('../fonts/SourceSansPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-Italic.woff') format('woff'),
		url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-ExtraLightItalic.eot');
	src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
		url('../fonts/SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-ExtraLightItalic.woff') format('woff'),
		url('../fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-ExtraLight.eot');
	src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
		url('../fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('../fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Light.eot');
	src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
		url('../fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-Light.woff') format('woff'),
		url('../fonts/SourceSansPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Black.eot');
	src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
		url('../fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-Black.woff') format('woff'),
		url('../fonts/SourceSansPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-Bold.eot');
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
		url('../fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-Bold.woff') format('woff'),
		url('../fonts/SourceSansPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../fonts/SourceSansPro-SemiBold.eot');
	src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
		url('../fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
