.content-seo{
    $this: &;
    margin: 80px 0px;
    @include _1200{
        margin: 20px 0 0;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 75px;
        @include _1200{
            grid-column-gap: 20px;
        }
        @include _576{
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
        }
        &--reverse{
            #{$this}__text{
                order: 2;
            }
            #{$this}__image{
                order: 1;
            }
        }
    }
    &__text{

    }
    &__image{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}