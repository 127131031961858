.content-gallery{
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 45px 0 0;
    &--2{
        grid-template-columns: repeat(2, 1fr);
    }
    &--3{
        grid-template-columns: repeat(3, 1fr);
    }
    &--4{
        grid-template-columns: repeat(4, 1fr);
    }
    @include _992{
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-columns: repeat(2, 1fr) !important;
    }
    @include _576{
        grid-template-columns: repeat(1, 1fr) !important;
    }
    &__item{
        $this: &;
        display: flex;
        flex-direction: column;
        position: relative;
        &-preview{
            height: 100%;
            width: 100%;
            position: relative;
            &::before{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #526ba1b5;
                border: 1px solid $main-clr-alt;
                background-image: url('../images/zoom.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 50px;
                opacity: 0;
                transition: opacity $tr-time;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
        }
        span{
            padding: 12px 0 0;
            font-size: 16px;
            line-height: 20px;
            color: $text-clr;
            @include _992{
                font-size: 14px;
                line-height: 18px;
                padding: 8px 0 0;
            }
        }
        &:hover{
            #{$this}-preview{
                &::before{
                    opacity: 1;
                }
            }
        }
    }
}