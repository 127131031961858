.container{
	max-width: calc(1755px + 30px);
	margin: 0 auto;
	padding: 0 15px;
}

.loading,
.disabled{
	opacity: .4;
	pointer-events: none;
	user-select: none;
}

.show-after-load{
	opacity: 0 !important;
	visibility: hidden !important;
}

#pageContainer{
	max-width: 1455px;
	margin: 0 auto;
	padding: 0 0 100px;
	@include _1200{
		padding: 0 0 50px;
	}
}

.js{
	&-openall{
		.hide{
			display: none;
		}
	}
}

.btn{
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	// background-color: transparent;

	// Виды
	&--red{
		background-color: #FF0033;
		border-radius: 8px;
		box-shadow: 0px 12px 30px rgba(255, 0, 51, 0.4);
		transition-property: box-shadow, background-color;
		transition-duration: $tr-time;
		span{
			font-weight: 700;
			font-size: 21px;
			line-height: 26px;
			color: #FFFFFF;
		}
		@include _1200{
			span{
				font-size: 16px;
				line-height: 24px;
			}
		}
		&:not(.success){
			&:hover{
				background-color: #D2002A;;
			}
		}
	
		&.success{
			background-color: #2BAD16;
			box-shadow: 0px 12px 30px rgba(43, 173, 22, 0.4);
		}
	}

	&--openall{
		position: relative;
		margin: 15px 0 0;
		font-size: 21px;
		line-height: 26px;
		color: $main-clr-alt;
		transition: color $tr-time;
		@include _1200{
			font-size: 15px;
			line-height: 18px;
		}
		&::after{
			content: '';
			display: block;
			position: relative;
			border-top: 1px solid $main-clr-alt;
			border-right: 1px solid $main-clr-alt;
			transform: rotate(135deg);
			width: 10px;
			height: 10px;
			margin: -3px 0px 0px 14px;
			transition: $tr-time;
			@include _1200{
				width: 8px;
				height: 8px;
				margin: -4px 0px 0px 14px;
			}
		}
		&:hover{
			color: $main-clr;
			&::after{
				border-color: $main-clr;
			}
		}
		&.opened{
			&::after{
				transform: rotate(315deg);
				margin: 8px 0 0 14px;
			}
		}
	}

	&--act{
		padding: 10px;
		svg{
			width: 24px;
    		height: 24px;
		}
		&[data-state="added"]{
			svg{
				fill: #FF0033;
			}
		}
	}

	&--border{
		font-weight: 700;
		font-size: 21px;
		line-height: 26px;
		color: $main-clr-alt;
		border: 1px solid $main-clr-alt;
		border-radius: 8px;
		transition-property: border-color, color;
		transition-duration: $tr-time;
		@include _1200{
			font-size: 16px;
			line-height: 24px;
		}
		&:hover{
			border-color: $main-clr;
			color: $main-clr;
		}
	}

	&--filled{
		font-weight: 700;
		font-size: 21px;
		line-height: 26px;
		color: #FFFFFF;
		background: $main-clr-alt;
		border-radius: 8px;
		box-shadow: 0px 12px 30px rgba(1, 36, 114, 0.37);
		transition: background-color $tr-time;
		@include _1200{
			font-size: 16px;
			line-height: 24px;
		}
		&:hover{
			background-color: #00184D;
		}
	}

	&--dotted{
		font-weight: 700;
		font-size: 21px;
		line-height: 26px;
		color: #9A9BA8;
		transition: color $tr-time;
		span{
			display: block;
			border-bottom: 1px dashed #9A9BA8;
			transition: border-color $tr-time;
		}
		&:hover{
			color: $main-clr;
			span{
				border-color: $main-clr;
			}
		}
	}

	&--back{
		width: fit-content;
		align-items: center;
		&:hover{
			svg{
				fill: $main-clr;
			}
			span{
				color: $main-clr;
			}
		}
		svg{
			width: 20px;
			height: 20px;
			transform: rotate(180deg);
			fill: #121212;
			margin-right: 10px;
			transition: fill $tr-time;
		}
		span{
			font-size: 21px;
			line-height: 26px;
			color: #121212;
			transition: color $tr-time;
		}
	}

	&--swal{
		height: 45px;
		padding: 0px 20px;
		margin: 0 10px;
		@include _1200{
			padding: 0 10px;
			margin: 0 5px;
		}
		@include _576{
			padding: 0;
			margin: 0;
		}
	}

	// Размеры
	&--s-login{
		padding: 16px 45px;
	}
}

.icon{
	display: inline-block;
	transition: opacity $tr-time;
	&:hover{
		opacity: 0.8;
	}
}


.breadcrumbs{
	padding: 25px 0 50px;
	@include _1200{
		padding: 15px 0 20px;
	}
	&-item{
		font-size: 18px;
		line-height: 24px;
		color: $text-clr;
		@include _1200{
			font-size: 15px;
			line-height: 18px;
		}
		&--link{
			transition: color $tr-time;
			&:hover{
				color: $main-clr;
			}
		}
		&--sep{
			color: #9A9BA8;
			padding: 0 10px;
			@include _1200{
				padding: 0 3px;
			}
		}
		&:last-child{
			color: #9A9BA8;
		}
	}

	&-signup-alert{
		padding: 0 0 25px;
	}
}

.page-title{
	font-weight: 900;
	font-size: 48px;
	line-height: 56px;
	color: $text-clr;
	&--solo{
		margin: 0 0 35px;
		@include _1200{
			margin: 0 0 25px;
		}
	}
	@include _1200{
		font-size: 24px;
		line-height: 28px;
	}
}

.page-section{
	
}

.help-text{
	$this: &;
	cursor: pointer;
	position: relative;
	&__icon{
		svg{
			width: 24px;
			height: 24px;
			fill: $main-clr;
		}
	}
	&__alert{
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		font-size: 18px;
		line-height: 22px;
		color: #121212;
		background: #FFFFFF;
		box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		padding: 20px 30px;
		visibility: hidden;
		opacity: 0;
		transition: $tr-time;
		z-index: 9;
		@include _1200{
			left: unset;
			right: 0;
			font-size: 12px;
			line-height: 18px;
			max-width: 270px;
			padding: 5px 12px;
		}
	}
	&:hover{
		#{$this}__alert{
			opacity: 1;
			visibility: visible;
		}
	}
}

.theme-notify{
	background-color: #1AC5F4;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	padding: 24px 40px;
	@include _1200{
		padding: 15px;
	}
	&__text{
		position: relative;
		z-index: 1;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: #FFFFFF;
		@include _1200{
			font-size: 15px;
			line-height: 18px;
		}
		a{
			color: #fff;
			text-decoration: underline;
			&:hover{
				text-decoration: none;
			}
		}
	}
	&__decor{
		position: absolute;
		background-color: $main-clr-alt;
		border-radius: 50%;
		&--1{
			width: 200px;
			height: 200px;
			left: -50px;
			top: -100px;
		}
		&--2{
			width: 35px;
			height: 35px;
			left: 180px;
			top: -20px;
		}
	}
}

.theme-tabs{
	&__head{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__name{
		cursor: pointer;
		padding: 15px 26px;
		background-color: #FFFFFF;
		box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		border: 1px solid transparent;
		font-size: 21px;
		line-height: 26px;
		color: #121212;
		transition: $tr-time;
		@include _1200{
			font-size: 15px;
			line-height: 18px;
			padding: 8px 10px;
		}
		&:not(:last-child){
			margin-right: 14px;
			@include _1200{
				margin-right: 10px;
			}
		}
		&:not(.active){
			&:hover{
				color: $main-clr;
			}
		}
		&.active{
			cursor: default;
			border-color: #C7C8CF;
			box-shadow: none;
		}
	}
	&__body{
		padding: 25px 0 0;
		@include _1200{
			padding: 15px 0 0;
		}
	}
	&__container{
		display: none;
		&.active{
			display: block;
		}
	}
}

.theme-sort{
	display: flex;
	flex-direction: row;
	align-items: center;
	@include _1200{
		align-items: flex-start;
		flex-direction: column;
	}
	&__name{
		font-size: 18px;
		line-height: 24px;
		color: #121212;
		@include _1200{
			font-size: 15px;
			line-height: 18px;
		}
	}
	&__items{
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-left: 20px;
		@include _1200{
			padding: 5px 0 0;
		}
		.radio-sort{
			&:not(:last-child){
				margin-right: 10px;
				@include _1200{
					margin-right: 5px;
				}
			}
		}
	}
}

.radio-sort{
	$this: &;
	display: block;
	&__field{
		display: none;
		&:checked{
			+ #{$this}__name{
				border-color: #121212;
				cursor: default;
			}
		}
		&:not(:checked){
			+ #{$this}__name{
				&:hover{
					color: $main-clr;
					&::after{
						border-color: $main-clr;
					}
				}
			}
		}
	
	}
	&__name{
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 24px;
		color: #121212;
		cursor: pointer;
		background-color: #FFFFFF;
		border: 1px solid #C7C8CF;
		border-radius: 18px;
		padding: 5px 25px;
		transition: color $tr-time;
		user-select: none;
		@include _1200{
			font-size: 12px;
			line-height: 16px;
			padding: 4px 8px;
		}
		&::after{
			content: '';
			display: block;
			position: relative;
			width: 10px;
			height: 10px;
			border-top: 1px solid #121212;
			border-right: 1px solid #121212;
			transform: rotate(135deg);
			margin-left: 10px;
			top: -2px;
			transition-property: border-color, transform, top;
			transition-duration: $tr-time;
		}
		&--reverse{
			&::after{
				transform: rotate(315deg);
				top: 3px;
			}
		}
	}
}

.theme-color-label{
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	@include _1200{
		font-size: 12px;
		line-height: 16px;
	}
	&--red{
		color: #FF0033;
	}
	&--yellow{
		color: #D19B11;
	}
	&--green{
		color: #2BAD16;
	}
}

.theme-counter{
	width: 145px;
	height: 45px;
	background: #FFFFFF;
	border: 1px solid #C7C8CF;
	border-radius: 5px;
	display: grid;
	grid-template-columns: 42px 1fr 42px;
	@include _1200{
		height: 35px;
		width: 110px;
		grid-template-columns: 30px 1fr 30px;
	}
	&__button{
		user-select: none;
		svg{
			width: 14px;
			height: 14px;
			fill: $text-clr;
			transition: fill $tr-time;
			@include _1200{
				width: 8px;
				height: 8px;
			}
		}
		&:hover{
			svg{
				fill: $main-clr;
			}
		}
	}
	&__field{
		border: none;
		border-left: 1px solid #C7C8CF;
		border-right: 1px solid #C7C8CF;
		text-align: center;
		font-size: 21px;
		line-height: 26px;
		@include _1200{
			font-size: 16px;
			line-height: 18px;
		}
	}
}

.theme-fileblock{
	display: grid;
	grid-template-columns: 60px 1fr;
	align-items: center;
	@include _1200{
		grid-template-columns: 30px 1fr;
	}
	&__icon{
		svg{
			width: 60px;
			height: 60px;
			fill: $main-clr-alt;
			@include _1200{
				width: 30px;
				height: 30px;
			}
		}
	}
	&__name{
		margin-left: 5px;
		p{
			font-size: 21px;
			line-height: 26px;
			color: $text-clr;
			margin: 0 0 5px;
			@include _1200{
				font-size: 15px;
				line-height: 18px;
				margin: 0;
			}
		}
		span{
			font-size: 18px;
			line-height: 22px;
			color: #626262;
			@include _1200{
				font-size: 12px;
				line-height: 14px;
			}
		}
	}
}


.swiper{
	transition: opacity $tr-time;
	opacity: 1;
	&-init{
		opacity: 0;
	}
	&-pagination{
		position: relative;
		z-index: 1;
		&-bullet{
			opacity: 1;
			width: 12px;
			height: 12px;
			border: 1px solid $main-clr-alt;
			background-color: transparent;
			transition: background-color $tr-time;
			margin: 0 9px;
			&-active{
				background-color: $main-clr-alt;
			}
			@include _992{
				width: 8px;
				height: 8px;
				margin: 0 4px;
			}
		}
	}
	&-navigation{
		cursor: pointer;
		position: relative;
		width: 40px;
		height: 40px;
		border: 1px solid #C7C8CF;
		border-radius: 8px;
		transition: border-color $tr-time;
		&::before{
			content: '';
			position: absolute;
			width: 12px;
			height: 12px;
			border-top: 1px solid #121212;
			border-right: 1px solid #121212;
			transform: rotate(225deg);
			left: 0;
			right: -4px;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: border-color $tr-time;
		}
		&:hover{
			border-color: $main-clr-alt;
			&::before{
				border-color: $main-clr-alt;
			}
		}
		&__next{
			transform: rotate(180deg);
			margin-left: 30px;
		}
		&__prev{
			margin-right: 30px;
		}
	}
}
.swiper-button-disabled{
	opacity: .6;
	pointer-events: none;
}

.no-photo{
	background-image: url('../images/placeholder.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.simple-counter{
    cursor: pointer;
	user-select: none;
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    background: #FFFFFF;
	align-items: center;
	@include _992{
		grid-template-columns: 18px 1fr 18px;
	}
    &__minus,
    &__plus{
        svg{
			width: 28px;
			height: 28px;
			fill: $text-clr;
			transition: fill $tr-time;
			@include _992{
				width: 18px !important;
				height: 18px !important;
			}
		}
		&:hover{
			svg{
				fill: $main-clr;
			}
		}
    }
    &__field{
        border: none;
        text-align: center;
        font-weight: 700;
		font-size: 32px;
		line-height: 38px;
        color: $text-clr;
		@include _992{
			font-size: 22px !important;
			line-height: 26px !important;
		}
    }
}

.swal2-title{
	@include _1200{
		padding: 10px 20px 0;
		font-size: 22px;
	}
}

.swal2-html-container{
	@include _1200{
		font-size: 16px;
	}
}

.swal2-actions{
	@include _576{
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 10px;
		margin: 0;
		padding: 10px 15px 0;
		.btn--swal{
			width: 100%;
			margin: 0;
		}
	}
}

.swal2-icon{
	@include _1200{
		width: 50px;
		height: 50px;
		.swal2-icon-content{
			font-size: 30px;
		}
	}
}


.fancybox__thumbs .carousel__slide .fancybox__thumb::after{
	border-color: $main-clr-alt !important;
}

.folowing-button{
	position: fixed;
	left: calc(100% - 142px);
	top: 48%;
	background-color: #FF0033;
	border-radius: 7.5px;
	padding: 8px 44px 10px;
	transform: rotate(-90deg);
	cursor: pointer;
	z-index: 2;
	transition: background-color .3s ease;
	@include _992{
		top: 92%;
		transform: none;
		left: 73%;
		z-index: 5;
	}
	@include _576{    
		display: flex;
		justify-content: center;
		width: 96%;
		top: 93%;
		left: 2%;
	}
	&:hover{
		background-color: #d2002a;
	}
	span{
		font-size: 21px;
		font-weight: 700;
		line-height: 22px;
		color: #fff;
	}
}