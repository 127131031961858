.content-files{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @include _1200{
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    }
    @include _992{
        grid-template-columns: repeat(3, 1fr);
    }
    @include _768{
        grid-template-columns: repeat(2, 1fr);
    }
    @include _576{
        grid-template-columns: repeat(1, 1fr);
    }
}

.content-file{
    $this: &;
    border: 1px solid #C7C8CF;
    border-radius: 5px;
    padding: 20px 25px;
    background-color: #fff;
    transition: background-color $tr-time;
    @include _1200{
        padding: 15px;
    }
    &:hover{
        background-color: $main-clr-alt;
        #{$this}__name{
            color: #fff;
        }
        #{$this}__icon{
            svg{
                fill: #fff;
            }
            span{
                color: #fff;
            }
        }
    }
    &__name{
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #121212;
        padding: 0 0 30px;
        transition: color $tr-time;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
            padding: 0 0 20px;
        }
    }
    &__icon{
        display: flex;
        align-items: center;
        svg{
            width: 24px;
            height: 24px;
            margin-right: 10px;
            @include _1200{
                width: 18px;
                height: 18px;
            }
        }
        span{
            font-size: 18px;
            line-height: 24px;
            color: #121212;
            transition: color $tr-time;
            @include _1200{
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}