body,
.adaptiv-fix{
	min-height: 100vh;
	margin: 0;
	color: $text-clr;
	font-family: 'Source Sans Pro' !important;
	display: flex;
	flex-direction: column;
	position: relative;
	&.opened{
		overflow: hidden;
	}
}
main{
	position: relative;
	flex: 1 0 auto;
}
p,ol,ul,h1,h2,h3,button,form{
	margin: 0;
	padding: 0;
}
em{
	font-style: normal;
	color: $main-clr;
}
button, input, textarea{
	font-family: inherit;
	&:focus{
		outline: none;
	}
}
input{
	min-width: 0;
	font-size: inherit;
	appearance: none;
}
ul, li{
	list-style-type: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
	-webkit-appearance: none;
	-moz-appearance: textfield;
}
a{
	text-decoration: none;
	transition: color $tr-time;
}
svg{
	vertical-align: middle;
	transition: fill $tr-time;
}
picture{
	display: block;
}
img{
	display: block;
}
source{
	display: none;
}
*, ::before, ::after{
	box-sizing: border-box;
}
:focus{
	outline: none;
}