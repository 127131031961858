.footer{
    background-color: #040C20;
    @include _576{
        padding-bottom: 25px;
    }
}

.footer-main{
    padding: 65px 0 70px;
    @include _1200{
        padding: 30px 0;
    }
    &__container{
        display: grid;
        grid-template-columns: 30% 12% 32% 1fr;
        grid-column-gap: 95px;
        @include _1200{
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 40px;
        }
        @include _992{
            grid-template-columns: 1fr;
        }
    }
}

.footer-catalog{
    &__title{
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        color: #FFFFFF;
        padding: 0 0 10px;
        margin: 0 0 15px;
        border-bottom: 1px solid #494E5E;
    }
    &__categories{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 65px;
        grid-row-gap: 18px;
        @include _576{
            grid-template-columns: 1fr;
        }
        a{
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            &:hover{
                color: $main-clr;
            }
        }
    }
}

.footer-menu{
    @include _992{
        padding: 24px 0 0;
        border-top: 1px solid #494E5E;
    }
    a{
        display: block;
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        color: #FFFFFF;
        &:not(:last-child){
            margin: 0 0 24px;
        }
        &:hover{
            color: $main-clr;
        }
    }
}

.footer-contacts{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 40px;
    @include _768{
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
    }
    &__item{
        &-title{
            font-size: 21px;
            line-height: 26px;
            color: #9A9BA8;
        }
        &-value{
            display: block;
            font-size: 21px;
            line-height: 26px;
            color: #FFFFFF;
            &:hover{
                color: $main-clr;
            }
        }
    }   
}

.footer-social{
    .icon{
        &:not(:last-child){
            margin-right: 15px;
        }
    }
}

.footer-address{
    padding: 20px 0 0;
    font-size: 21px;
    line-height: 26px;
    color: #FFFFFF;
}

.footer-bottom{
    border-top: 1px solid #494E5E;
    padding: 18px 0;
    @include _992{
        padding: 30px 0;
    }
    &__container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        @include _992{
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__text{
        font-size: 18px;
        line-height: 24px;
        color: #9A9BA8;
        transition: color $tr-time;
    }
}

.footer-policy{
    a:hover{
        color: #fff;
    }
    @include _992{
        padding: 18px 0 10px;
    }
}

.footer-ps{
    display: block;
    svg{
        width: 145px;
        height: 30px;
        margin-left: 6px;
    }
    &:hover{
        .footer-bottom__text{
            color: #fff;
        }
    }
}