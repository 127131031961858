@import 'components/catalog-card.scss';

.header{
    position: relative;
    z-index: 10;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &-part{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &--fit{
            flex: 1 0 auto;
            padding-left: 50px;
        }
    }

    &-top{
        background-color: #EFF4FA;
        padding: 10px 0;
    }
    &-main{
        background-color: #fff;
    }
}

.header-logo{
    svg{
        fill: $main-clr-alt;
        width: 240px;
        height: 35px;
    }
}

.header-email{
    margin-right: 85px;
    @include _1200{
        margin: 10px 0 0;
        text-align: center;
    }
    a{
        font-size: 21px;
        line-height: 26px;   
        color: $main-clr-alt;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
            margin: 0;
            font-weight: 600;
        }
        &:hover{
            color: $main-clr;
        }
    }
}

.header-contacts{
    $this: &;
    &__item{
        margin-left: 45px;
        &:hover{
            #{$this}__value{
                color: $main-clr;
            }
        }
        @include _1200{
            margin: 0;
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
    &__title{
        font-size: 21px;
        line-height: 28px;
        color: #9A9BA8;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
            margin: 0 0 3px;
        }
    }
    &__value{
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        color: #012472;
        transition: color $tr-time;
        margin-left: 12px;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
            margin: 0;
        }
    }
}

.header-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    &__nav{
        
    }
    &__wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 -18px;
    }
    &__item{
        a{
            display: block;
            padding: 35px 18px;
            font-weight: 600;
            font-size: 21px;
            line-height: 26px;
            color: $text-clr;
            &:hover{
                color: $main-clr;
            }
        }
    }
}

.header-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    &__item{
        &:not(:first-child){
            margin-left: 30px;
        }
    }
}

.header-link{
    $this: &;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    @include _1200{
        grid-template-columns: 30px 1fr;
    }
    &:hover{
        #{$this}__icon{
            svg{
                fill: $main-clr;
            }
        }
        #{$this}__name{
            color: $main-clr;
        }
    }
    &__label{
        background-color: #FF0033;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 3px;
        right: 10px;
        border-radius: 50%;
        font-size: 0;
        display: none;
        &:not(:empty){
            display: block;
        }
        @include _1200{
            width: 8px;
            height: 8px;
        }
    }
    &__icon{
        position: relative;
        svg{
            width: 30px;
            height: 30px;
            fill: $text-clr;
            transition: fill $tr-time;
            @include _1200{
                width: 22px;
                height: 22px;
            }
        }
    }
    &__name{
        font-size: 21px;
        line-height: 26px;
        color: $text-clr;
        transition: color $tr-time;
        @include _1200{
            font-size: 16px;
            line-height: 18px;
        }
    }
}

#js-catalog-menu-close{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2;
}

.catalog-button{
    $this: &;
    cursor: pointer;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    margin-right: 36px;
    &:hover,
    &.opened{
        #{$this}__name{
            color: $main-clr;
        }
        #{$this}__icon{
            span{
                background-color: $main-clr;
            }
        }
    }
    &.opened{
        #{$this}__icon{
            span{
                &:nth-child(1){
                    top: 5px;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:nth-child(3){
                    bottom: 6.8px;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    &__icon{
        width: 18px;
        height: 14px;
        position: relative;
        span{
            display: block;
            position: absolute;
            background-color: $text-clr;
            width: 100%;
            height: 2px;
            border-radius: 5px;
            transition-duration: $tr-time;
            &:nth-child(1){
                top: 0;
            }
            &:nth-child(2){
                top: calc(50% - 1px)
            }
            &:nth-child(3){
                bottom: 0;
            }
        }
    }
    &__name{
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        color: $text-clr;
        transition: color $tr-time;
    }
    
}

.catalog-menu{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    transition-property: opacity, top;
    transition-duration: $tr-time;
    right: 0;
    left: 0;
    &.opened{
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
    &__container{
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-column-gap: 30px;
        background-color: #FFFFFF;
        padding: 35px 30px;
        box-shadow: 0px 39px 83px rgba(0, 0, 0, 0.14);
    }
    &__main{
        
    }
    &__drop{

    }
    &__wrap{
        display: none;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        &.opened{
            display: grid;
        }
    }
}

.category-listblock{
    background-color: #EFF4FA;
    border-radius: 8px;
    padding: 28px 25px;
    @include _1200{
        padding: 15px 20px;
    }
    &__haschild{
        padding-right: 30px;
        position: relative;
        &::after{
            content: '';
            display: block;
            border-top: 1px solid $main-clr-alt;
            border-right: 1px solid $main-clr-alt;
            width: 8px;
            height: 8px;
            transform: rotate(41deg);
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: border-color $tr-time;
        }
    }
    &__item{
        display: block;
        cursor: pointer;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $main-clr-alt;
        transition: color $tr-time;
        @include _1200{
            font-size: 16px;
            line-height: 22px;
        }
        &:hover,
        &.opened{
            color: $main-clr;
            &::after{
                border-color: $main-clr
            }
        }
        &:not(:last-child){
            margin: 0 0 16px;
            @include _1200{
                margin: 0 0 10px;
            }
        }
    }
}

.header-mobile{
    position: relative;
    z-index: 53;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;
    .header-logo{
        svg{
            width: 160px;
            height: 22px;
        }
    }
}

.header-burger{
    padding: 10px;
    &__wrap{
        width: 20px;
        height: 16px;
        position: relative;
    }
    span{
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        background-color: $text-clr;
        transition: $tr-time;
        transition-property: top, bottom, opacity, transform;
        &:nth-child(1){
            top: 0;
        }
        &:nth-child(2){
            top: 0;
            bottom: 0;
            margin: auto;
        }
        &:nth-child(3){
            bottom: 0;
        }
    }

    &.opened{
        span{
            &:nth-child(1){
                top: calc(50% - 1px);
                transform: rotate(45deg);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                bottom: calc(50% - 1px);
                transform: rotate(-45deg);
            }
        }
    }
}

.mobile-menu{
    position: fixed;
    z-index: 51;
    top: 64px;
    bottom: 0;
    left: 100%;
    width: 100%;
    background-color: #fff;
    overflow: scroll;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) $tr-time;
    &.opened{
        left: 0%;
    }
    &__wrap{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__buttons{
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #DFDFDF;
    }
    &__button{
        padding: 15px 0px;
        &:first-child{
            border-right: 1px solid #DFDFDF;
        }
    }
    &__main{
        padding: 15px 15px 20px;
    }
    &__nav{
        display: flex;
        flex-direction: column;
        padding: 0 0 35px;
        &-item{
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #121212;
            &:not(:last-child){
                margin: 0 0 16px;
            }
        }
        &-haschild{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::after{
                content: '';
                position: relative;
                display: block;
                width: 8px;
                height: 8px;
                border-top: 1px solid $text-clr;
                border-right: 1px solid $text-clr;
                transform: rotate(45deg);
            }
        }
    }
    &__search{
        padding: 0 0 20px;
    }
    &__contacts{
        padding: 20px 30px;
        background-color: #EFF4FA;
        .header-contacts{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}