@import 'base/app';
@import 'components/content-text';
@import 'vendors/fancybox.min';


.page-share{
    margin: 80px 0 0;
    @include _1200{
        margin: 20px 0 0;
    }
    &__title{
        font-weight: 700;
        font-size: 42px;
        line-height: 48px;
        color: $text-clr;
        padding: 0 0 25px;
        @include _1200{
            font-size: 22px;
            line-height: 26px;
            padding: 0 0 15px;
        }
    }

    .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
        margin: 2px 25px 0 0;
        border-radius: 8px;
        overflow: hidden;
        @include _1200{
            margin: 2px 10px 0 0;
            .ya-share2__icon{
                width: 30px;
                height: 30px;
                background-size: 20px 20px;
            }
        }
    }
}